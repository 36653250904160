import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"

const BackgroundSection = ({ className, children, filename }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const image = data.images.edges.find(n =>
    n.node.relativePath.includes(filename)
  )

  if (!image) {
    return null
  }

  const backgroundFluidStack = [
    image.node.childImageSharp.fluid,
    `radial-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    )`
  ].reverse()

  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={backgroundFluidStack}
      backgroundColor={`#040e18`}
    >
      {
        children
      }
    </BackgroundImage>
  )
}

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;

  /* &::before {
    width: ${({ width }) => width || "100%"} !important; 
    height: ${({ height }) => height || "100%"} !important;
    background-image: radial-gradient(
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.85)
      ),
      url(${({ src }) => src}) !important;
    background-color: black;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    content: "";
    left: 0;
    position: fixed;
    top: 0;
    will-change: transform;
    z-index: -1; */
  /* } */
`

export default StyledBackgroundSection
