import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Background from "../components/background"

const Style = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .info-box {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: rgba(12, 47, 90, 0.8);
    white-space: nowrap;
  }

  .divider {
    width: 50vw;
    border-top: 1px solid white;

    @media only screen and (max-width: 508px) {
      width: 70vw;
    }
  }

  .company-name {
    color: white;
    margin-top: 0;
  }

  .divider,
  .company-name,
  .message {
    margin-bottom: 2rem;
  }

  .email-link {
    text-decoration: none;
    color: white;
    text-shadow: none;
  }

  .email-message {
    margin-bottom: 1rem;
  }
`
const UnderConstruction = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <Style>
      <Background filename="mgh.png">
        <div className="info-box">
          <h1 className="company-name">{data.site.siteMetadata.title}</h1>
          <p className="message">Website Under Construction</p>
          <hr className="divider" />
          <div className="email-message">For inquries, contact</div>
          <a
            className="email-link"
            href="mailto: inquiry@medexchinaus.com?subject=Medexchange Inquiry"
          >
            inquiry@medexchinaus.com
          </a>
        </div>
      </Background>
    </Style>
  )
}

export default UnderConstruction
